  $fonts:

    ('/fonts/OpenSans-Light.ttf', 'opensans', 100, 'normal')
    ('/fonts/OpenSans-Regular.ttf', 'opensans', 200, 'normal')
    ('/fonts/OpenSans-Bold.ttf', 'opensans', 400, 'normal')
    ('/fonts/ravu.ttf', 'iconfont', 100, 'normal')

  ;

  @each $font in $fonts {
    @include font-setting(nth($font,1), nth($font,2), nth($font,3), nth($font,4))
  }
