/*!
 * Bootstrap-iconpicker v1.7.0
 *
 * Copyright 2013-2015 Victor Valencia Rico.
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @recktoner.
 */

.iconpicker .caret {
    margin-left: 10px !important;
}

.iconpicker {
    min-width: 60px;
}

.iconpicker input.search-control {
    margin-bottom: 6px;
    margin-top: 6px;
}

div.iconpicker.left .table-icons {
    margin-right: auto;
}

div.iconpicker.center .table-icons {
    margin-left: auto;
    margin-right: auto;
}

div.iconpicker.right .table-icons {
    margin-left: auto;
}

.table-icons .btn {
    min-height: 30px;
    min-width: 35px;
    text-align: center;
    padding: 0;
    margin: 2px;
}

.table-icons td {    
    min-width: 39px;
}

.popover {
    max-width: inherit !important;
}

.iconpicker-popover {
  z-index: 1050 !important;
}

.iconpicker-popover .search-control {
    margin-bottom: 6px; 
    margin-top: 6px;
}