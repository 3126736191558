.navbar__top {
	display:block;
	background:#fff;
	position:fixed;
	width:100%;
	left:0;
	right:0;
	z-index:100;
	top: 0;
	box-shadow:0 0 15px 0 rgba(0,0,0,.3);

	.logotext{
		float: left;
		line-height: 70px;
		font-weight: 200;
		font-size: 14px;
		font-weight: 400;
		color: #fff;
		text-decoration: none;
	}


	&.navbar {
		z-index:100 !important;
	}

	.navbar-brand {
		color:#3E4153 !important;
		font-size:20px !important;
	}

	.navigation{
		width: 100%;
		margin: 0 auto;
		display: block;

		> ul {
			list-style: none;
			float: right;

			> li {
				line-height: 70px;
				float: left;
				margin-left: 15px;
				font-size: 14px;

				&:hover {
					background:none;
				}
			a {
				padding-left: 15px;
				padding-right: 15px;
				display: block;
				color:#3E4153;
				text-decoration: none;
				font-weight: 400;
				font-size: 14px;
			}

		}
		}
	}


	.navbar-left {
    	display:block;

        @media (min-width: $screen-sm-min) {
            display:none;
        }
	}
}
