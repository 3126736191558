

// Create default mixin for iconfont in :before or :after
@mixin icon($name,$uni) {
    [class*="icon--"][class*="--#{$name}"]:after {content:'#{$uni}';}
}

// Mixin to create @fontface
@mixin font-setting($font, $fontname, $fontweight: 100, $fontstyle: default) {
  @font-face {
    font-family: '#{$fontname}';
    src: url('#{$font}') format('truetype');
    font-weight: $fontweight;
    font-style: $fontstyle;
  }
}