$font-family-sans-serif:'Open Sans', sans-serif;
$body-bg:#EBEFF2;

@import 'bootstrap';

@import 'fontawesome';

@import 'partials/_mixins';
@import 'partials/_typography';
@import 'partials/_icons';
@import 'partials/navbar';
@import 'partials/sidebar';
@import 'partials/main';
@import 'main.scss';

body {
    padding-top:50px;
}

#sortable i.fa-sort{
	cursor: pointer;
}