/*
 * Sidebar
 */



.sidebar {
	display:none;

	@media (min-width: $screen-sm-min) {
		position: fixed;
		top: 51px;
		bottom: 0;
		left: 0;
		z-index: 100;
		display: block;
		padding: 20px;
		overflow-x: hidden;
		overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
		background-color: #262B33;
	}

	.nav-sidebar {
		margin-right: -21px;
		margin-bottom: 20px;
		margin-left: -20px;

		ul.sub-menu {
			list-style-type: disc;
			box-shadow:inset 0px 0px 5px 2px rgba(0,0,0,.3);
			padding-left:0;

			&[aria-expanded="true"] {
				background:#1F242A;
			}

			li {
				list-style-type: disc;

				a {
					color:#D3D4D6;
					font-size:12px;
					padding:15px 40px;
					display:block;
					transition:background 500ms;
					font-weight:500;

					&:hover {
						background:rgba(255,255,255,.2);
						text-decoration:none;
					}

					ul li a{
    					text-decoration: none;
					}
				}

				a .fa{
    				margin-right: 15px;
                    margin-left: 5px;
				}
			}

			ul.sub-menu{

				box-shadow:inset 0px 0px 0px 0px rgba(0,0,0,.0) !important;
				border-bottom: 1px solid rgba(255,255,255,.1);
				margin-left: 0;

				li{
    				list-style-type: none;
    				margin-left: 40px;
				}
			}
		}

		> li {
			overflow:hidden;
			position:relative;

			&[aria-expanded="true"] {
				background:#1F242A;
			}

			&.active > a {
				color:#fff;
			}

			> a {
				padding:20px 10px 20px 45px;
				display:block;
				position:relative;
				color:rgba(255,255,255,.8);
				font-size:13px;
				transition:500ms;

				.fa {
					position:absolute;
					left:15px;
					top:22px;
					font-size:15px;
				}

				&:hover {
					color:white;
					background:rgba(255,255,255,.2);
				}

				&:active, &:focus {
					background:none;
				}
			}
		}
	}
}
