$lh: 20px;

// Create icon style 2.0, please use for future applications, refactor code where possible
[class*="icon--"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: iconfont !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  // Create Fixed Width (fw) icon
  &[class*="--fw--"]:after  { width: $lh; text-align: center;display: inline-block;}

  // Create margins
  &[class*="--mr--"] { margin-right: $lh / 2;}
  &[class*="--ml--"] { margin-left: $lh / 2;}
  &[class*="--mrs--"] { margin-right: $lh / 4;}
  &[class*="--mls--"] { margin-left: $lh / 4;}
  &[class*="--mra--"] { margin-right: auto;}
  &[class*="--mla--"] { margin-left: auto;}

}

.btn-icon{
  width: 40px;
  height: 40px;
  font-size: 30px;
}

.btn-icon i:after{
  width: auto !important;
  text-align: left !important;
  display: inline !important;
}

.table-icons .btn.disabled{
  pointer-events: none;
}

.arrow-left:after{
  content: '\2B05';
}

.arrow-right:after{
  content: '\27A1';
}


// Create (space seperated) icon list
$icons:
  bandaid       '\e900',
  biohazard     '\e901',
  desinfection  '\e902',
  fire          '\e903',
  infection     '\e904',
  plus          '\e905',
  protection    '\e906',
  search        '\e907',
  star          '\e908',
  x             '\e90a',
  noun_677160   '\e90f',
  noun_677161   '\e910',
  noun_677164   '\e911',
  noun_677167   '\e912',
  noun_677168   '\e913',
  noun_677169   '\e914',
  noun_677170   '\e915',
  noun_677171   '\e916',
  noun_677172   '\e917',
  noun_677173   '\e918',
  noun_677174   '\e919',
  noun_677175   '\e91a',
  noun_677176   '\e91b',
  noun_680502   '\e91c',
  noun_680503   '\e91d',
  noun_680504   '\e91e',
  noun_680505   '\e91f',
  noun_680506   '\e920',
  noun_680507   '\e921',
  noun_680508   '\e922',
  noun_680509   '\e923',
  noun_680510   '\e924',
  noun_680511   '\e925',
  noun_680512   '\e926',
  noun_680513   '\e927',
  noun_680514   '\e928',
  noun_680515   '\e929',
  noun_680517   '\e92a',
  noun_680518   '\e92b',
  noun_680519   '\e92c',
  noun_680520   '\e92d',
  noun_680521   '\e92e',
  noun_680522   '\e92f',
  noun_687194   '\e930',
  noun_687196   '\e931',
  noun_687198   '\e932',
  noun_687200   '\e933',
  noun_687201   '\e934',
  noun_687202   '\e935',
  noun_687204   '\e936',
  noun_687205   '\e937',
  noun_687243   '\e938',
  noun_687244   '\e939',
  noun_687245   '\e93a',
  noun_687248   '\e93b',
  dropper       '\e90d',
  tube          '\e90e',
  pdf           '\e90b',
  link          '\e90c',
  home          '\e909',
;


// Loop through all the icons
@each $icon in $icons {
  @include icon(nth($icon,1),nth($icon,2));
}


