html,body{
  font-family: Arial, Helvetica, sans-serif;
  color: #343435;
}

a{
  color: #1330ab;
  text-decoration: none;
}

a:hover{
  color: #BE001F;
  text-decoration: none;
}

.fa-big{
  cursor: pointer;
  font-size: 20px;
}

.fa-pencil{
  color: #bb9400;
}

.fa-times{
  color: #880000;
}

.fa-check{
  color: #bbbbbb;
}

.fa-plus{
  color: #008800;
}

.menu-item-active{
  color: white;
  background: rgba(255,255,255,0.2);
}

.sortable-cursor{
  cursor: all-scroll;
}

.list-group-item.bg-warning{
  background-color: #fcf8e3;
}

.list-group-item.bg-danger{
  background-color: #f2dede;
}

.btn-primary{
  background-color: #1330ab;
}

.btn-primary:hover{
  background-color: #BE001F;
}

.fa-checked{
  color: #008800;
}